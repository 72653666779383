<template>
  <a
    class="mgw-cruise-results text-center px-4 py-3 text-decoration-none"
    :class="isCruising ? 'state-active' : ''"
    @click="toggleCruisingHandler"
    title="Automatically scroll"
  >{{ cruiseState }}</a>
</template>

<script>
  import { DebugHelper } from '../lib/helpers.js';

  export default {
    mounted()
    {
      window.addEventListener('keydown', this.keyboardInterrupt);
      window.addEventListener('scroll', this.scrollInterrupt);
    },
    unmounted()
    {
      window.removeEventListener('keydown', this.keyboardInterrupt);
      window.removeEventListener('scroll', this.scrollInterrupt);
      this.stop();
    },
    data()
    {
      return {
        isCruising: false,
        cruiseHandle: null,
        cruiseInterval: 1000/60,
        lastScollPosition: null,
        previousTimestamp: 0,
        stopAnimationBased: null,
        useAnimationMethod: false,
        scrollDistance: 12,
        intervalScrollDistance: 2
      }
    },
    computed:
    {
      cruiseState()
      {
        return this.isCruising ? 'Cruising' : 'Cruise';
      }
    },
    methods:
    {
      getScrollDistance()
      {
        return this.useAnimationMethod
          ? this.scrollDistance
          : this.intervalScrollDistance;
      },
      scrollInterrupt(e)
      {
        // Check if the user scrolled up.
        if (this.lastScollPosition > window.pageYOffset)
        {
          this.stop('scroll interrupt');

          e.preventDefault();
          e.stopPropagation();
        }

        this.lastScollPosition = window.pageYOffset;
      },
      keyboardInterrupt(e)
      {
        if (e.key == 'Escape' || e.key == 'Esc' || e.key == 'ArrowUp')
        {
          this.stop('keyboard interrupt');

          e.preventDefault();
          e.stopPropagation();
        }
      },
      toggleCruisingHandler(e)
      {
        this.toggle();

        e.preventDefault();
        e.stopPropagation();
      },
      cruise()
      {
        if (this.lastScollPosition == null)
        {
          if (DebugHelper.inDebugMode())
          {
            console.log(`Last scroll pos null`);
          }

          this.lastScollPosition = window.pageYOffset;

          if (DebugHelper.inDebugMode())
          {
            console.log(`Last scroll pos updated: ${this.lastScollPosition}`);
          }
        }

        if (window.scrollBy)
        {
          if (DebugHelper.inDebugMode())
          {
            console.log(
              `Scrolling from ${this.lastScollPosition} by ${this.getScrollDistance()}`
            );
          }

          // TODO: Work on a way to only run once the scroll has completed.
          // This is for the smooth scroll feature in Chromium.

          window.scrollBy({
            left: 0,
            top: this.getScrollDistance(),
            behavior: 'instant'
          });
        }
        else
        {
          let newScrollY = this.lastScollPosition + this.getScrollDistance();

          if (DebugHelper.inDebugMode())
          {
            console.log(
              `Scrolling from ${this.lastScollPosition} to ${newScrollY}`
            );
          }

          window.scroll(null, newScrollY);
        }
      },
      stop(reason = null)
      {
        if (DebugHelper.inDebugMode())
        {
          if (reason)
          {
            console.log(`Cruise control: Stopping because of ${reason}`);
          }
          else
          {
            console.log('Cruise control: Stop');
          }
        }

        if (this.useAnimationMethod)
        {
          window.cancelAnimationFrame(this.cruiseHandle);
          this.stopAnimationBased = true;
        }
        else
        {
          clearInterval(this.cruiseHandle);
        }

        this.cruiseHandle = null;
        this.isCruising = false;

      },
      start()
      {
        if (DebugHelper.inDebugMode())
        {
          console.log('Cruise control: Start');
        }

        this.reset();

        this.useAnimationMethod
          ? this.startAnimationBased()
          : this.startIntervalBased();
      },
      reset()
      {
        this.isCruising = false;
        this.stopAnimationBased = false;
        this.previousTimestamp = 0;
      },
      startAnimationBased()
      {
        this.cruiseHandle = window.requestAnimationFrame(
          (ts) => {
            if (!this.stopAnimationBased || ts !== this.previousTimestamp)
            {
              this.previousTimestamp = ts;
              this.cruise();
              this.isCruising = true;

              this.startAnimationBased();
            }
          }
        );
      },
      startIntervalBased()
      {
        if (!this.isCruising)
        {
          this.cruiseHandle = setInterval(
            () => this.cruise(),
            this.cruiseInterval
          );

          this.isCruising = true;
        }
      },
      toggle()
      {
        this.isCruising ? this.stop() : this.start();
      }
    }
  }
</script>
