<!-- This is component allows the user to go to the top of the page.  -->
<template>
  <a class="mgw-back-up text-center px-4 py-3" @click="backUp" title="Page top">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-up" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708l6-6z"/>
      <path fill-rule="evenodd" d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
    </svg>
  </a>
</template>

<script type="text/javascript">
  export default {
    methods:
    {
      backUp(e)
      {
        e.preventDefault();
        e.stopPropagation();

        window.scroll({
          left: null,
          top: 0
        });
      }
    }
  }
</script>

<style lang="sass">
</style>
