<template>
  <nav class="search-utilities navbar navbar-expand p-3 pe-none">
    <div class="row d-flex flex-grow-1">
      <div class="col text-end utilities right-aligned-utilities">
        <cruise-results class="pe-auto" />
        <back-up class="pe-auto" />
      </div>
    </div>
  </nav>
</template>

<script type="text/javascript">
  import BackUp from './back-up.vue';
  import CruiseResults from './cruise-results.vue';

  export default {
    components: {
      BackUp,
      CruiseResults
    }
  }
</script>

<style lang="sass">
.search-utilities
  position: fixed
  top: calc(100vh - 6rem)
  width: 100%
  z-index: 1

.utilities > a
  opacity: 0.35
  transition: opacity ease 0.25s

  &:hover,
  &.state-active
    opacity: 1.0

  display: inline-block
  background: #484641cf
  color: white !important
  cursor: pointer

  // &:active,
  // &:hover
  // color: #ccc
  background: #484641e6
  text-decoration: none

</style>
